<template>
  <div class="container-sm">
    <br><br><br><br><br>
    <router-link
      :to="{ name: 'home' }"
      style="color: #000"
    >
      <div class="d-flex align-items-center">
        <div class="box-back text-center mr-1">
          <i class="fas fa-long-arrow-left" />
        </div>
        <h5 class="text-center font-weight-bolder m-0 t_black">
          ถอนเครดิต
        </h5>
      </div>
    </router-link>

    <div class="card p-1 mt-1">
      <div class="text-center header-dark">
        <h5 class="font-weight-bolder m-0">
          ถอนเครดิตเข้าบัญชีธนาคาร
        </h5>
      </div>
      <div
        v-if="agent.status_wit === '0'"
        class="text-center"
      >
        <small
          class="text-danger"
        >*ขออภัยขณะนี้เว็บไซต์ได้ทำการปิดระบบถอนเงินชั่วคราว</small>
      </div>
      <div
        v-else
        class="text-center"
      >
        <small
          class="text-yellow"
        >*ระบบจะโอนเงินเข้าบัญชีของท่าน ภายในเวลา 1-2 นาที</small>
      </div>
      <div
        v-if="wto === null && agent.status_wit === '1'"
        class="row text-center"
      >
        <div
          class="col-6"
        >
          <button
            :disabled="!User.acc_no"
            class="btn-gold-btns w-100"
            @click="selected('acc')"
          >
            บัญชีธนาคาร
          </button>
        </div>

        <div
          class="col-6"
        >
          <button
            :disabled="!User.trueaccno"
            class="btn-gold-btns w-100"
            @click="selected('true')"
          >
            True Wallet
          </button>
        </div>
      </div>

      <div
        v-if="User.trueaccno && wto === 'true'"
        class="card-bank mt-1 p-1"
      >
        <div class="d-flex align-items-center">
          <div>
            <img
              src="/bankIcon/true.svg"
              alt="bank"
              height="35"
            >
          </div>
          <div>
            <h5
              class="m-0 font-weight-bolder"
              style="color: #000"
            >
              ทรูมันนี่ วอลเล็ต
            </h5>
          </div>
        </div>

        <div class="mt-1 mb-1 d-flex justify-content-between">
          <img
            src="/chip.png"
            alt="chip"
            height="40"
          >
        </div>

        <div>
          <div class="box-bank">
            <h4 class="m-0 dark font-weight-bolder">
              {{ User.trueaccno }}
            </h4>
          </div>
          <div>
            <h5 class="m-0 dark">
              {{ User.name }} {{ User.surname }}
            </h5>
          </div>
        </div>
      </div>

      <div
        v-if="User.acc_no && wto === 'acc'"
        class="card-bank mt-1 p-1"
      >
        <div class="d-flex align-items-center">
          <div>
            <img
              :src="`/bankIcon/${User.path_photo}`"
              alt="bank"
              height="35"
            >
          </div>
          <div>
            <h5
              class="m-0 font-weight-bolder"
              style="color: #000"
            >
              {{ User.bankname }}
            </h5>
          </div>
        </div>

        <div class="mt-1 mb-1 d-flex justify-content-between">
          <img
            src="/chip.png"
            alt="chip"
            height="40"
          >
        </div>

        <div>
          <div class="box-bank">
            <h4 class="m-0 dark font-weight-bolder">
              {{ User.acc_no }}
            </h4>
          </div>
          <div>
            <h5 class="m-0 dark">
              {{ User.name }} {{ User.surname }}
            </h5>
          </div>
        </div>
      </div>

      <div class="mt-1">
        <input
          id="balance"
          v-model="amount"
          :disabled="agent.status_wit === '0'"
          type="number"
          name="balance"
          placeholder="จำนวนเงินที่ต้องการถอน"
          class="form-control"
        >

      </div>
      <b-button
        :disabled="agent.status_wit === '0' || loading === true"
        class="mt-1 btn-gold-btns"
        block
        @click="submit()"
      >
        <b-spinner
          v-if="loading"
          small
          class="mr-1"
        />
        ถอนเงิน
      </b-button>
    </div>

    <div class="text-left text-head-hisotry mt-2">
      ประวัติการถอนเงิน [รายการล่าสุด 5 รายการ]
    </div>

    <div
      class="
        w-100
        border-solid
        rounded-lg
        text-color
        bg-table
        border-10
        table-responsive
      "
    >
      <table
        role="table"
        aria-busy="false"
        aria-colcount="3"
        class="table b-table table-sm"
      >
        <thead role="rowgroup">
          <tr role="row">
            <th
              role="columnheader"
              scope="col"
              aria-colindex="1"
              class="small text-left pl-1"
            >
              <div>วันที่/เวลา</div>
            </th>
            <th
              role="columnheader"
              scope="col"
              aria-colindex="2"
              class="small text-center pr-1"
            >
              <div>จำนวนเงิน</div>
            </th>
            <th
              role="columnheader"
              scope="col"
              aria-colindex="3"
              class="small text-center pr-1"
            >
              <div>สถานะ</div>
            </th>
          </tr>
        </thead>
        <tbody
          v-if="withdrawData.length > 0"
          role="rowgroup"
        >
          <tr
            v-for="(key, index) in withdrawData"
            :key="index._id"
            role="row"
            class="b-table-empty-row"
          >
            <td>{{ key.timestamp }}</td>
            <td class="text-center">
              {{ key.amount }}บาท
            </td>
            <td class="text-center">
              <b-badge
                v-if="key.status === 'processing'"
                variant="light-warning"
              >
                <small> กำลังดำเนินการ</small>
              </b-badge>
              <b-badge
                v-if="key.status === 'waiting' || key.status === 'error'|| key.status === 'waiting_approve'"
                variant="light-warning"
              >
                <small> รอดำเนินการ</small>
              </b-badge>
              <b-badge
                v-if="key.status === 'manual_success' || key.status === 'success'"
                variant="light-success"
              ><small> สำเร็จ</small>
              </b-badge>
              <b-badge
                v-if="key.status === 'return'"
                variant="light-success"
              ><small> คืนเครดิต สำเร็จ</small>
              </b-badge>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr
            role="row"
            class="b-table-empty-row"
          >
            <td
              colspan="3"
              role="cell"
            >
              <div
                role="alert"
                aria-live="polite"
              >
                <div class="text-color text-center pt-2 pb-2 fs-14">
                  <img
                    src="@/assets/preview.svg"
                    alt="empty"
                    height="60"
                  > ไม่พบข้อมูล
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import { BBadge, BButton, BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BBadge,
    BButton,
    BSpinner,
  },
  props: {
    // eslint-disable-next-line
    // UserProfile: Object,
  },
  data() {
    return {
      loading: false,
      show: true,
      balance: 0.0,
      amount: '',
      withdrawData: '',
      UserData: JSON.parse(localStorage.getItem('userData')),
      User: {},
      wto: null,
      agent: {},
    }
  },
  mounted() {
    this.GetAgentData()
    this.Getbalance()
    this.GetUser(this.UserData.userID)
    this.getwithdraw()
  },
  methods: {
    selected(val) {
      this.wto = val
    },
    GetUser(userID) {
      this.$http
        .get(`/users/show/${userID}`)
        .then(response => {
          // console.log(response.data.ListGames)
          this.User = response.data
          // console.log(this.User)
        })
        .catch(error => console.log(error))
    },
    Getbalance() {
      this.$http
        .get('/balance/getbalance')
        .then(response => {
          // console.log(response.data)
          this.balance = response.data.balance
          if (this.balance < 0) {
            this.balance = 0
          }
        })
        .catch(error => console.log(error))
    },
    getwithdraw(username) {
      this.show = true
      this.$http
        .get(`/withdraw/username/${username}`)
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.withdrawData = response.data
          let index = 0
          this.withdrawData.forEach(items => {
            this.withdrawData[index].timestamp = moment(items.created_at)
              .tz('Asia/Bangkok')
              .format('DD/MM/YYYY HH:mm:ss')
            index += 1
          })
        })
        .catch(error => console.log(error))
    },
    GetAgentData() {
      this.$http
        .get('/agent/show')
        .then(response => {
          // console.log(response.data.ListGames)
          this.agent = response.data
        })
        .catch(error => console.log(error))
    },
    submit() {
      this.show = true
      this.loading = true
      // this.amount = this.balance.balance
      if (Number(this.amount) <= Number(this.balance)) {
        if (this.wto === null) {
          this.show = false
          this.SwalError('กรุณาเลือกบัญชีที่ต้องการให้เว็บโอนเข้า')
        } else {
          const params = {
            username: this.User.username,
            amount: this.amount,
            uid: this.User.id,
            wto: this.wto,
          }
          // console.log(params)
          this.$http
            .post('/withdraw/store', params)
            .then(response => {
              this.show = false
              this.loading = false
              this.amount = ''
              this.Success(response.data.message)
              this.getbalance(this.User.username)
              this.getwithdraw(this.User.username)
            })
            .catch(error => {
              this.show = false
              this.loading = false
              this.SwalError(error.response.data.message)
            })
        }
      } else {
        this.show = false
        this.loading = false
        this.SwalError('ยอดเครดิต ไม่เพียงพอสำหรับการถอน')
      }
    },
    copy() {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #FFF">คัดลอกเลขบัญชีสำเร็จ</h3>',
        showConfirmButton: false,
        timer: 1500,
        background: '#202124',
      })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color:#000">ทำรายการสำเร็จ<h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color:#000">ขออภัยค่ะ!<h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
  },
}
</script>

<style scoped>
.btn-theme-two {
  width: 100%;
  background: linear-gradient(180deg, #5a499d, #bd6aaa);
  border-radius: 10px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 38px;
  color: #FFFFFF !important;
  font-size: 1.2rem !important;
  font-weight: normal !important;
  border-color: #f2994a !important;
}
.btn2 {
  font-size: 1rem !important;
}
.form-control {
  background: #ffffff !important;
  border-radius: 14px !important;
  color: #232323 !important;
  height: 38px !important;
  font-size: 1.2rem !important;
  filter: drop-shadow(0 0 1px #f2994a);
}
.card-bank {
  min-height: 175px;
  border-radius: 14px;
  /* background-image: url('/bg-bank2.svg'); */
  background: linear-gradient(
      0deg,
      rgba(185, 153, 91, 0.9),
      rgba(221, 204, 151, 0.9),
      rgba(185, 153, 91, 0.9)
    ),
    url("/bg-bank2.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 6px;
  padding: 10px;
}
.dark {
  color: #000 !important;
}
.header-dark {
  background: linear-gradient(180deg, #626166, #3d3a3d) !important;
  padding: 10px;
}
.card-list {
  margin: 0 15px;
  background: #2e2e2e;
  box-shadow: 0 4px 10px rgb(0 0 0 / 12%);
  border-radius: 10px;
  padding: 10px;
}

.card {
  border-radius: 14px;
  border: 1px solid rgb(51, 51, 51);
  background: #232323 !important;
  color: rgb(255, 255, 255) !important;
}
.text-yellow {
  color: #ffc107 !important;
}
.bg-balance {
  background-image: url(https://www.ltobet.com/assets/img/bg-banner-home.svg) !important;
  background-repeat: repeat !important;
  background-size: 100% auto !important;
}
.btn-withdraw {
  height: 36px;
  background-color: #ffffff27;
  color: #fff !important;
  border: 1px solid #fff;
  font-weight: 700;
  padding: 0 6px;
  border-radius: 2px !important;
}
.btn-depo {
  height: 36px;
  background: #fff;
  border: none;
  font-weight: 700;
  padding: 0 6px;
  border-radius: 2px !important;
}
.theme-ltobet .book_bank_content .bank-icon .media-icon {
  width: 20px;
  height: 20px;
  display: block;
  float: left;
  border-radius: 4px;
  position: relative;
}
.btn-orange {
  background-image: linear-gradient(103deg, #0c2574 1%, #341082);
  width: 100%;
  border-radius: 10px !important;
  color: #ffffff !important;
}
.box-ac-items {
  padding: 10px;
  border-radius: 5px;
  border: #000 1px solid;
  background-color: #fff;
  color: #000;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
.btnlogout {
  background: linear-gradient(180deg, #626166, #3d3a3d) !important;
  color: rgb(255, 255, 255);
  width: 100%;
  border: none;
  border-radius: 10px;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 0 6px;
  height: 36px;
}
</style>
